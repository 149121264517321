body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
    margin: 0;
    scroll-behavior: smooth;
}

fieldset {
    border-style: none;
    display: block;
    margin: 0;
    padding: 0;
}

label {
    font-weight: 600;
    margin: 30px 0 0;
}

.StripeElement {
    border: 1px solid #909090;
    padding: 8px 10px;
    margin: 10px 0;
}

a {
    color: #404040;
}
h2 {
    margin: 5px 0;
}

h3 {
    font-size: 16px;
    margin: 5px 0;
}

p {
    margin: 10px 0;
    font-size: 16px;
    line-height: 1.4em;
}

input,
select {
    box-sizing: border-box;
    border: 0;
    border: 1px solid #909090;
    color: #202020;
    font-size: 16px;
    margin: 10px 0;
    padding: 8px 10px;
    width: 100%;
    border-radius: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
}

.warnThem {
    border-color: rgba(255, 211, 0, 1);
    border-width: 4px;
    transition: all 1s;
    outline: none;
}

.warnThemAfter {
    border-radius: 5px;
    padding: 5px 15px;
    text-align: center;
    margin: auto;
    max-width: 250px;
    background-color: rgba(255, 211, 0, 1);
    color: #000;
    display: inline-block;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #fff;
    background-position: right 50%;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
    padding: 0.5em;
    padding-right: 1.5em;
    color: black;
    margin: 10px auto;
    outline: 1px;
    border: 1px solid #909090;
    height: 38px;
    border-radius: 0;
}

.labelthing {
    text-transform: uppercase;
    font-size: 14px;
    display: block;
    margin-top: 10px;
}

@keyframes ld-spin {
    0% {
        transform: rotate(0deg);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
        transform: rotate(180deg);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
        transform: rotate(360deg);
    }
}

.ld-ring {
    width: 1em;
    height: 1em;
    position: relative;
    color: inherit;
    display: inline-block;
}

.ld-ring:after {
    position: absolute;
    margin: auto;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: ' ';
    display: inline-block;
    background: center center no-repeat;
    background-size: cover;
    border-radius: 50%;
    border-style: solid;
    border-width: 0.15em;
    background-clip: padding-box;
    -webkit-background-clip: padding-box;
    border-color: currentColor currentColor currentColor transparent;
    box-sizing: border-box;
}

.ld-ext-right {
    position: relative;
    transition: all 0.3s;
    transition-timing-function: ease-in;
    overflow: hidden;
}

.ld-ext-right.running {
    padding-right: 2.5em !important;
}

.ld.ld-spin {
    -webkit-animation: ld-spin 1s infinite;
    animation: ld-spin 1s infinite;
}

.ld-ext-right > .ld {
    top: 50%;
    left: auto;
    right: 1em;
    position: absolute;
    margin: -0.5em;
    opacity: 0;
    z-index: -100;
    transition: all 0.3s;
    transition-timing-function: ease-in;
}

.ld-ext-right.running > .ld {
    opacity: 1;
    z-index: auto;
    visibility: visible;
}
